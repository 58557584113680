import React from 'react';
import { Game } from './game';

export default function App() {
  return (
    <>
    <h1> Challenge your friend to a game of Tic-Tack-Toe!</h1>
    <Game />
    </>
  )
}
